@use "@angular/material" as mat;
@import "./common-styles.scss";

html {
  scroll-behavior: smooth;
}

@import "@angular/material/theming";

@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$frontend-desktop-theme: mat.define-light-theme(
    $app-primary,
    $app-accent,
    $app-warn
);

@include mat.all-legacy-component-themes($frontend-desktop-theme);

.alternate-theme {
  $alternate-second-primary: mat.define-palette(mat.$blue-grey-palette);
  $alternate-second-accent: mat.define-palette(mat.$deep-orange-palette, 400);
  $alternate-second-warn: mat.define-palette(mat.$yellow-palette);

  $alternate-second-theme: mat.define-light-theme(
      $alternate-second-primary,
      $alternate-second-accent,
      $alternate-second-warn
  );

  @include mat.all-legacy-component-themes($alternate-second-theme);
}

// ************** DARK:
.dark-theme {
  $primary: mat.define-palette(mat.$amber-palette);
  // Removed A300, which is not a valid hue
  $accent: mat.define-palette(mat.$deep-orange-palette, A200, A100, A400);
  $warn: mat.define-palette(mat.$yellow-palette);
  $theme: mat.define-dark-theme($primary, $accent, $warn);
  @include mat.all-legacy-component-themes($theme);
}

.darklight-theme {
  $primary: mat.define-palette(mat.$amber-palette);
  // Removed A300, which is not a valid hue
  $accent: mat.define-palette(mat.$deep-orange-palette, A200, A100, A400);
  $warn: mat.define-palette(mat.$yellow-palette);
  $theme: mat.define-light-theme($primary, $accent, $warn);
  @include mat.all-legacy-component-themes($theme);
}

// ************** CANDY:
.candy-theme {
  $primary: mat.define-palette(mat.$indigo-palette);
  $accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
  $warn: mat.define-palette(mat.$red-palette);
  $theme: mat.define-light-theme($primary, $accent, $warn);
  @include mat.all-legacy-component-themes($theme);
}

html,
body {
  height: 100%;
}
body {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
  align-items: center;
  justify-content: center;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $deep-background-color;
}

.center-me {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.center-me-without-toolbar-row {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
}

.login-button-icon {
  line-height: 0.9em;
}

/* Tailwind */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Material */
@import "assets/styles/material.scss";

/* Common */
@import "assets/styles/common.scss";

// animate.css
@import url("assets/styles/animate.css");

body {
  @extend .dark-theme;
}
