// Widths
$left-panel-width: 22em;

// Colors
$dark-gray: #202125;
$gray: #3d3e42;
$light-gray: #d9d9d9;
$red: #df655e;
$primaryBgColor: $red;
$primaryBgColorDisabled: #a25854;
$secondaryBgColor: $gray;
$secondaryBgColorDisabled: $dark-gray;
$darkred: #e0564d;
$blue: #6e9cff;
$lilac: #7143c9;
