@import "variables";

// Mat input
.mat-focused .mat-form-field-required-marker {
  color: $red !important;
}

// Mat checkbox
.mat-checkbox-indeterminate.mat-accent .mat-checkbox-background,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: $blue !important;
}

.mat-checkbox-checkmark-path {
  stroke: $gray !important;
}

.mat-checkbox-layout .mat-checkbox-label {
  color: white !important;
}

.mat-checkbox-frame {
  border-color: white !important;
}

.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element,
.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent
  .mat-ripple-element {
  background-color: $blue !important;
}

// Mat slider
.mat-accent .mat-slider-track-fill,
.mat-accent .mat-slider-thumb,
.mat-accent .mat-slider-thumb-label {
  background-color: $red !important;
}

.mat-slider-track-background {
  background-color: #686868 !important;
}

// Mat slide toggle
.mat-slide-toggle.mat-checked {
  .mat-ripple-element,
  .mat-slide-toggle-thumb {
    background-color: white !important;
  }

  .mat-slide-toggle-bar {
    background-color: rgba(255, 255, 255, 0.38) !important;
  }
}
