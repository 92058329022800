@use "@angular/material" as mat;
@import 'variables';

$font-family: Roboto, "Helvetica Neue", sans-serif;
$top-toolbar-height-small: 24px;
$top-toolbar-height-medium: 32px;
$top-toolbar-height-large: 48px;
$tab-bar-height-xsmall: 24px;
$tab-bar-height-small: 30px;
$tab-bar-height-medium: 38px;
$tab-bar-height-large: 48px;
$button-height-xsmall: 20px;
$button-height-small: 24px;
$button-height-medium: 32px;
$button-height-large: 36px;
$screen-height-small: 600px;
$screen-height-medium: 800px;
$screen-height-large: 1000px;
$desktop-height: calc(100vh - 48px);
$desktop-height-small: calc(100vh - 24px);
$desktop-height-medium: calc(100vh - 32px);
$desktop-height-large: calc(100vh - 48px);
/*$desktop-work-area-height: calc(100% - 72px);*/
$desktop-padding-gap: 8px;
$desktop-padding: 0 $desktop-padding-gap $desktop-padding-gap;
$desktop-padding-gap-small: 8px;
$desktop-padding-small: 0 $desktop-padding-gap-small $desktop-padding-gap-small;
$max-width-menus-change: 800px;
$very-small-width-menus-change: 550px;
$top-toolbar-media-max-width-change: 650px;
$frame-gap: 64px;
$font-very-large: 20px;
$font-large: 18px;
$font-normal: 14px;
$font-small: 12px;
$font-very-small: 11px;

div.aframe-container {
  div#ar-content-container {
    video {
      z-index: 0 !important;
      position: relative !important;
      top: -100% !important;
      left: 0 !important;
      width: 100% !important;
      height: 100% !important;
    }
  }
}

.desktop-work-area {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  height: 100%;

  .left-sidenav {
    overflow-y: auto;
    min-width: 330px;
  }
  .edit-view-container {
    flex-direction: column;
    // display: flex;
    // justify-content: stretch;
    height: 100%;
    width: 100%;
  }
}

@media (max-width: $max-width-menus-change) {
  div.desktop-work-area {
    height: 100%;
    margin-top: $desktop-padding-gap-small !important;;
  }
}


@media (max-width: $very-small-width-menus-change) {
  tiwp-desktop-top-toolbar button span,
  tiwp-edit-label span {
    font-size: $font-very-small !important;
  }
  tiwp-desktop-top-toolbar .toolbar-title span {
    font-size: $font-normal;
  }
}

@media (min-width: $very-small-width-menus-change) and (max-width: $max-width-menus-change) {
  tiwp-desktop-top-toolbar button span,
  tiwp-edit-label span {
    font-size: $font-small !important;
  }
  tiwp-desktop-top-toolbar .toolbar-title span {
    font-size: $font-large;
  }
}

.folder-selector-tree-panel {
  margin: 8px 16px;
  min-width: fit-content;
  max-width: calc(100vw - 48px);
  min-height: fit-content;
}

.question-main-tab-group .mat-tab-body-content {
  overflow-x: hidden;
}

/*
@import '@angular/material/theming';
// @include mat-core();
$frontend-desktop-accent: mat-palette($mat-amber);
$main-accent-color: mat-color($frontend-desktop-accent);

$frontend-desktop-primary: mat-palette($mat-indigo, 300!*$mat-deep-purple,50, 300, 700*!);
$main-primary-color: mat-color($frontend-desktop-primary, 1, 0.3);
*/

// $deep-background-color: #f7f1f0;

@import "@angular/material/theming";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue.
$app-primary: mat.define-palette(
  mat.$indigo-palette,
  300 /*$mat-deep-purple,50, 300, 700*/
);
$app-primary-highlight: mat.define-palette(mat.$amber-palette, 100);
$app-accent: mat.define-palette(mat.$yellow-palette);
$app-warn: mat.define-palette(mat.$red-palette);
$primary: mat.get-color-from-palette($app-primary);
$primary-highlight: mat.get-color-from-palette($app-primary-highlight);
$accent: mat.get-color-from-palette($app-accent);
$warn: mat.get-color-from-palette($app-warn);
$app-accent2: mat.define-palette(mat.$yellow-palette, 100);
$accent2: mat.get-color-from-palette($app-accent2);


$soft-white-text-color: rgba(255, 255, 255, 0.87);
$default-text-color: rgba(0, 0, 0, 0.87);
$deep-background-color: #f7f6f9;
$screen-background-color: #fdfdfd;
$bottomless-background-color: #f0f0f0;
$hover_background: rgba(0, 0, 0, 0.08);
$active_background: mat.get-color-from-palette(mat.$amber-palette, 50);

.tiwp-red-icon-button {
  color: $soft-white-text-color;
  background-color: $red;
}
.tiwp-dark-view-form {
  background-color: $gray !important;
  color: $soft-white-text-color !important;
  .tiwp-light-view-form {
    background-color: $screen-background-color !important;
    color: $dark-gray !important;
  }
}

.tiwp-light-view-form {
  background-color: $screen-background-color !important;
  color: $dark-gray !important;
}

$list-color-default: $default-text-color;
$list-color-1: #7c00de;
$list-color-2: #ab68ee;
$list-color-3: #de00d6;
$list-color-4: #87007f;
$list-color-5: #0040ac;

$color_primary: #7986cb;
/*
$screen-backdrop-overlay: rgba(121, 134, 203, 0.6);
$cdk-overlay-dark-backdrop-background: rgba(121, 134, 203, 0.6);
.cdk-overlay-dark-backdrop {
  background: $screen-backdrop-overlay;
}
*/

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

/*
$success-colors: mat-palette($mat-green, 400);
$warning-colors: mat-palette($mat-amber, 400);
*/

.mat-tab-group {
  .mat-tab-group {
    .mat-tab-label-content {
      font-weight: normal;
    }
  }
  .mat-tab-label-content {
    font-weight: bold;
  }
  .mat-form-field-appearance-legacy .mat-form-field-underline {
    background-color: rgba(0, 0, 0, 0.12);
  }
  .mat-toolbar .mat-form-field-appearance-legacy.mat-focused .mat-form-field-ripple {
    background-color: $primary;
  }
}

.cdk-overlay-container {
  .cdk-global-overlay-wrapper {
    .mat-dialog-container {
      overflow: hidden;
      background-color: $gray !important;
    }
  }
  &.cdk-global-overlay-wrapper.mat-dialog-container {
    overflow: hidden;
  }
  mat-option {
    img {
      &.admin-organization-types {
        max-width: 32px;
        vertical-align: middle;
      }
    }
  }
  .mat-select-panel {
    &.selection-asset-types-panel,
    &.selection-project-types-panel {
      max-height: 320px;
    }
  }
}

mat-select {
  &.admin-organization-types {
    .mat-select-value {
      display: none;
    }
  }
}

mat-form-field {
  &.admin-organization-types.empty-selection {
    .mat-form-field-infix {
      padding: 1.5em 0 1em 0;
    }
  }
}
