@import 'variables';

body {
  cursor: default; /* cursor por defecto */
}

body.cursor-dragging {
  cursor: grabbing !important; /* cursor al arrastrar */
}

// Text colors
.text-color-red {
  color: $red !important;
}

.text-color-dark-gray {
  color: $dark-gray !important;
}

// Backgrounds
.bg-dark-gray {
  background-color: $dark-gray !important;
}

.bg-gray {
  background-color: $gray !important;
}

.bg-light-gray {
  background-color: $light-gray !important;
}

.bg-red {
  background-color: $red !important;
}

// Buttons
.primary-button {
  background-color: $primaryBgColor !important;
  color: #fff !important;
  &.mat-button-disabled {
    color: $gray !important;
    background-color: $primaryBgColorDisabled !important;
  }
}


.primary-color {
  color: $primaryBgColor;
}

.accent-color {
  color: $accent;
}


.secondary-button {
  background-color: $secondaryBgColor !important;
  color: #fff !important;
  &.mat-button-disabled {
    color: $gray !important;
    background-color: $secondaryBgColorDisabled !important;
  }
}

@media screen and (min-height: 600px) {
  .desktop {
    .desktop {
      &__body {
        height: $desktop-height-medium;
      }

      &__content {
        height: $desktop-height-medium;
      }

      &__work-area {
        height: $desktop-height-medium;
      }
    }
  }
}

@media screen and (min-height: 800px) {
  .desktop {
    .desktop {
      &__body {
        height: $desktop-height-large;
      }

      &__content {
        height: $desktop-height-large;
      }

      &__work-area {
        height: $desktop-height-large;
      }
    }
  }
}

//
tiwp-geometry-question, twip-themed-classroom, tiwp-scene-details, tiwp-augmented-manual-properties-box {
  .mat-form-field-wrapper {
    padding-bottom: 0.5rem !important;
  }
}

// Desktop
.desktop {
  &__body {
    width: 100vw;
    height: $desktop-height-small;
    box-sizing: border-box;
    overflow: hidden;
    border-radius: 0;
    padding-top: 0;
  }

  &__content {
    height: $desktop-height-small;
    margin: auto;
    vertical-align: middle;
  }

  &__work-area {
    height: $desktop-height-small;
  }

  &__work-area-left-panel {
    width: $left-panel-width;
    min-width: $left-panel-width;
    max-width: $left-panel-width;
  }
}

// Scrollbar
::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb:hover {
  background: $light-gray;
  border-radius: 6px 6px 6px 6px;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  background-clip: padding-box;
}

::-webkit-scrollbar-corner {
  visibility: hidden;
}

// Inputs
.input-gray {
  color: black !important;

  &.readonly {
    .mat-form-field-label {
      mat-label {
        color: white !important;
      }
    }
    .mat-form-field-flex {
      color: $light-gray !important;
      background-color: $gray !important;
      border-radius: 4px 4px 0 0 !important;
      padding: 0.6rem !important;
    }
  }

  &.pb-0 {
    .mat-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  &.pb-small {
    .mat-form-field-wrapper {
      padding-bottom: 0.5rem !important;
    }
  }

  .mat-form-field-flex {
    background-color: $light-gray !important;
    border-radius: 4px 4px 0 0 !important;
    padding: 0.6rem !important;
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-infix {
    padding-bottom: 0 !important;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: black !important;
  }

  &__error {
    background: white !important;
    padding: 4px !important;
    border-left: 4px $red solid !important;
  }

  &__with-resetting {
    .mat-form-field-flex, .mat-mdc-text-field-wrapper {
      border-radius: 4px 0 0 0 !important;
    }
    .mdc-line-ripple {
      color: $red;
    }
  }
}

input:-webkit-autofill {
  -webkit-background-clip: text;
}

// Lists
.list-gray {
  .mat-list-item-content {
    color: black !important;
  }
}

// Textarea
.textarea-gray {
  color: black !important;

  .mat-form-field-flex {
    background-color: $light-gray !important;
    border-radius: 0 !important;
    padding: 0.6rem !important;
  }

  .mat-form-field-underline {
    display: none !important;
  }

  .mat-form-field-infix {
    padding-bottom: 0 !important;
  }

  .mat-form-field.mat-focused .mat-form-field-label {
    color: black !important;
  }

  &__error {
    background: white !important;
    padding: 4px !important;
    border-left: 4px $red solid !important;
  }
}

.textarea-gray-basic {
  color: black !important;
  background-color: $light-gray !important;
  border-radius: 4px 4px 0 0 !important;

  &__error {
    background: white !important;
    padding: 4px !important;
    border-left: 4px $red solid !important;
  }
}

// Selects
.select-padding-rounded {
  .mat-form-field-flex {
    border-radius: 4px 4px 0 0 !important;
    padding: 0.6rem 0.6rem 0 0.6rem !important;
  }
}
.common-select-gray, .common-select-grey {
  color: $dark-gray !important;
  .mat-form-field-label-wrapper {
    color: $dark-gray !important;
  }
  .mat-select-trigger {
    background-color: $light-gray !important;
    border-radius: 4px !important;
    padding: 0.5rem !important;
    .mat-select-min-line, .mat-select-arrow {
      color: $dark-gray !important;
    }
  }
  .mat-form-field-type-mat-select .mat-form-field-label, .mat-select-value {
    color: $dark-gray;
  }
}
tiwp-reports {
  .report-select {
    &.select-date {
      // padding: 0.5rem 0;

      .mat-mdc-form-field-infix{
        padding-top: 1rem;
      }
      .mat-date-range-input-inner {
        color: $light-gray;
      }
      .mat-date-range-input-separator {
        color: $light-gray;
      }
      .mat-mdc-text-field-wrapper {
        height: 3rem;
      }
    }
    .mat-mdc-text-field-wrapper {
      border: 1px solid $light-gray;
      border-radius: 4px 4px 0 0;
    }
    .mat-mdc-select-value {
      padding-top: 0.5rem;
    }
    .mat-mdc-select-trigger {
      height: 3rem;
    }
    .mat-mdc-icon-button {
      color: $light-gray;
    }
    .mat-mdc-form-field {
      color: $light-gray;
    }
    mat-label {
      color: $light-gray;
    }
    .mat-mdc-select-min-line {
      color: $light-gray;
    }
  }
  .mat-mdc-option {

  }
}

.mat-mdc-select-panel {
  background-color: white !important;
  border-radius: 4px !important;
  padding: 0.5rem !important;
  .tiwp-reports-option {
    background-color: white !important;
    &.search {
      color: $gray;
      background-color: $secondaryBgColor;
    }
    .mdc-list-item__primary-text {
      color: black !important;
      .mat-select-search-clear {
        color: white;
      }
    }
    .mat-select-search-input {
      color: white;
      background-color: $gray;
    }
  }
}


.select-white {
  .mat-select-trigger {
    background-color: white !important;
    border-radius: 4px !important;
    padding: 0.5rem !important;
  }
}
.select-container {
  margin-bottom: 4px;
  display: flex;
  align-items: normal;
}


.select-gray, .select-yellow, .common-select-gray {
  .mat-mdc-select-arrow {
    color: $light-gray !important;
  }

  .mat-form-field-flex {
    background-color: $light-gray !important;
  }
  .mat-form-field.mat-focused {
    .mat-select-arrow,
    .mat-form-field-label {
      color: black !important;
    }
  }
  .mat-form-field-ripple,
  .mat-form-field-underline::before {
    background-color: transparent !important;
  }

  .mat-form-field-subscript-wrapper {
    padding: 0 !important;
  }

  &__error {
    background: white !important;
    padding: 4px !important;
    border-left: 4px $red solid !important;
  }

  &__with-resetting {
    .mat-form-field-flex, .mat-mdc-text-field-wrapper {
      border-radius: 4px 0 0 0 !important;
    }

    .mdc-line-ripple {
      color: $red;
    }
  }
}
.select-yellow {
  .mat-form-field-flex {
    background-color: #c5c566 !important;
  }
}
// Tabs
.secondary-tab-group {
  .mat-tab-header {
    border-bottom-color: #686868 !important;
  }

  .mat-tab-label-content {
    color: rgba(255, 255, 255, 0.38) !important;
    opacity: 1 !important;
    font-weight: 400;
  }

  .mat-tab-label {
    opacity: 1 !important;
  }

  .mat-tab-label-active .mat-tab-label-content {
    color: white !important;
    font-weight: 500;
  }

  .mat-ink-bar {
    background-color: white !important;
  }
}

// Borders
.border-gray {
  border-color: $gray !important;
}

.border-l-gray {
  border-left-color: $gray !important;
}

.border-light-gray {
  border-color: $light-gray !important;
}

.border-l-light-gray {
  border-left-color: $light-gray !important;
}

.border-red {
  border-color: $red !important;
}

.border-l-red {
  border-left-color: $red !important;
}
